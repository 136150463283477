import { mapActions, mapState } from 'vuex';
import { spatialFunctions } from '../utilities/spatialFunctions';

export const drawingMixin = {
    data() {
        return {
            drawShapeColor: '#0000cc',
        };
    },
    methods: {
        ...mapActions('oneMap', ['setMapTool', 'clearMapTool']),
        // create the marker features that are used for drawing
        getMarkerFeature(lat, lng) {
            return {
                // using letters and the count to create unique values between different object arrays
                //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                //    but not errors that will cause issues in the actual execution/functionality
                id: 'C' + this.drawMarkers.length,
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lng],
                    coordSys: 'LatLong',
                },
                properties: {},
                styling: {
                    SymbologyColour: this.drawShapeColor,
                    SymbologySize: 1,
                    PolygonColor: this.drawShapeColor,
                    PolygonTransparency: 0,
                },
            };
        },
        // set the drawing type
        drawPoint() {
            this.setMapTool('drawPoint');
        },
        // for line and polygon, start the other shapes that will be needed to draw
        drawLine() {
            this.setMapTool('drawLine');
            this.$store.commit('oneMap/addDrawLine', {
                id: 'L' + this.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.drawShapeColor,
                },
            });
        },
        drawPolygon() {
            this.setMapTool('drawPolygon');
            // add a line and a polygon, the line makes the polygon look like it has the open border edge while being drawn
            this.$store.commit('oneMap/addDrawLine', {
                id: 'L' + this.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.drawShapeColor,
                },
            });
            this.$store.commit('oneMap/addDrawPolygon', {
                id: 'P' + this.drawPolygons.length,
                geometry: {
                    coordinates: [[]],
                    type: 'Polygon',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonColor: this.drawShapeColor,
                    PolygonBorderColour: this.drawShapeColor,
                    PolygonTransparency: 0.8,
                },
            });
        },
        // if a point, just add it and clear the mapTool
        pointClickHandler(markerFeature) {
            if (this.activeMapTool == 'drawPoint') {
                this.$store.commit('oneMap/addDrawMarker', markerFeature);
                this.cancelDraw();
            }
        },
        lineClickHandler(markerFeature) {
            // if it is the first point, draw a point
            if (this.drawLines.at(-1).geometry.coordinates.length == 0) {
                this.$store.commit('oneMap/addDrawMarker', markerFeature);
            } else if (this.drawLines.at(-1).geometry.coordinates.length == 1) {
                this.removeLastDrawMarker();
            }
            // add the new vertex to the line and polygon
            this.$store.commit(
                'oneMap/pushLineVertex',
                markerFeature.geometry.coordinates
            );
        },
        polygonClickHandler(markerFeature) {
            // if it is the first point, draw a point
            if (this.drawLines.at(-1).geometry.coordinates.length == 0) {
                this.$store.commit('oneMap/addDrawMarker', markerFeature);
            } else if (this.drawLines.at(-1).geometry.coordinates.length == 1) {
                this.removeLastDrawMarker();
            }
            // add the new vertex to the line and polygon
            this.$store.commit(
                'oneMap/pushLineVertex',
                markerFeature.geometry.coordinates
            );
            this.$store.commit(
                'oneMap/pushPolygonVertex',
                markerFeature.geometry.coordinates
            );
        },
        pointDoubleClickHandler() {
            // this page intentionally left blank
        },
        // end the line
        lineDoubleClickHandler(isSaving) {
            let drawLines = this.drawLines;
            // removes the 2nd click in the double, just duplicated coordinates otherwise
            drawLines.at(-1).geometry.coordinates.pop();
            this.$store.commit('oneMap/mutateDrawLines', drawLines);
        },
        // don't need isSaving here, but we do in the components that have this function instead
        polygonDoubleClickHandler(isSaving) {
            // remove the outline line
            this.removeLastDrawLine();
            var drawPolygons = this.drawPolygons;
            drawPolygons.at(-1).styling.PolygonTransparency = 0;
            // removes the 2nd click in the double, just duplicated coordinates otherwise
            drawPolygons.at(-1).geometry.coordinates[0].pop();
            this.$store.commit('oneMap/mutateDrawPolygons', drawPolygons);
        },
        cancelDraw() {
            // confirm that we don't clear a different tool
            if (
                ['drawPoint', 'drawLine', 'drawPolygon'].includes(
                    this.activeMapTool
                )
            ) {
                this.clearMapTool();
            }
        },
        removeLastDrawMarker() {
            let drawMarkers = this.drawMarkers;
            drawMarkers.pop();
            this.$store.commit('oneMap/mutateDrawMarkers', drawMarkers);
        },
        removeLastDrawLine() {
            let drawLines = this.drawLines;
            drawLines.pop();
            this.$store.commit('oneMap/mutateDrawLines', drawLines);
        },
        // we never use this, I think it is here to be consistent with the 2 functions above
        removeLastDrawPolygon() {
            let drawPolygons = this.drawPolygons;
            drawPolygons.pop();
            this.$store.commit('oneMap/mutateDrawPolygons', drawPolygons);
        },
    },
    computed: {
        ...mapState('oneMap', [
            'activeMapTool',
            'clickCoords',
            'hasDoubleClicked',
            'drawMarkers',
            'drawLines',
            'drawPolygons',
        ]),
    },
    watch: {
        // use lots of nested functions so we can override
        //  them in other components if needed
        clickCoords(newCoords) {
            var [lat, lng] = spatialFunctions.validateCoordinates({
                latlng: newCoords,
            });
            let markerFeature = this.getMarkerFeature(lat, lng);
            switch (this.activeMapTool) {
                case 'drawPoint':
                case 'measureLocation':
                    this.pointClickHandler(markerFeature);
                    break;
                case 'drawLine':
                case 'measureDistance':
                    this.lineClickHandler(markerFeature);
                    break;
                case 'drawPolygon':
                case 'measureArea':
                case 'map3d':
                    this.polygonClickHandler(markerFeature);
                    break;
            }
        },
        // watch for double-clicks to handle as needed
        hasDoubleClicked(newVal) {
            if (newVal) {
                switch (this.activeMapTool) {
                    case 'drawPoint':
                    case 'measureLocation':
                        this.pointDoubleClickHandler();
                        break;
                    case 'drawLine':
                        this.lineDoubleClickHandler(true);
                        break;
                    case 'measureDistance':
                        this.lineDoubleClickHandler(false);
                        break;
                    case 'drawPolygon':
                        this.polygonDoubleClickHandler(true);
                        break;
                    case 'measureArea':
                    case 'map3d':
                        this.polygonDoubleClickHandler();
                        break;
                }
                this.clearMapTool();
                this.$store.commit('oneMap/mutateHasDoubleClicked', false);
            }
        },
    },
};

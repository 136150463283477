<template>
    <div class="outer-container">
        <div class="content-container p-3">
            <div class="d-flex flex-column">
                <CustomFloatingLabel
                    :config="{
                        label: 'Name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        id="threeDVisName"
                        ref="threeDVisName"
                        autocomplete="off"
                        type="text"
                        v-model="threeDVisName"
                        class="normal_input"
                        min="0"
                    />
                </CustomFloatingLabel>
                <CustomFloatingLabel
                    :config="{
                        label: 'Description',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        id="threeDVisDescription"
                        ref="threeDVisDescription"
                        autocomplete="off"
                        type="text"
                        v-model="threeDVisDescription"
                    />
                </CustomFloatingLabel>

                <b-form-file
                    accept=".ctws"
                    class="mb-1 text-start uploadThreeD-form"
                    id="new3DModelUpload"
                    name="new3DModelUpload"
                    ref="new3DModelUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="threeDFileValidation()"
                ></b-form-file>
                <div class="descText mt-2">
                    *Click Draw button, and then click on the map to draw a
                    polygon, double click to finish drawing and submit an EVS
                    model.
                </div>

                <SingleProjectModal
                    :isShow="showProjectSelectModal"
                    @setSelectedProject="setSelectedProject"
                ></SingleProjectModal>
            </div>
        </div>

        <div class="bottom-btn-container px-2">
            <div class="my-auto w-100">
                <b-button
                    class="buttonSelect map-btn m-1 "
                    @click="startDraw"
                    :disabled="map3dFeatureCount() == true"
                >
                    Draw
                </b-button>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="clear3DVisModel"
                    :disabled="map3dFeatureCount() == false"
                >
                    Clear
                </b-button>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="submit3DVisModel()"
                >
                    Submit
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Add any custom component as a leaflet control
 */
import { mapState, mapGetters, mapMutations } from 'vuex';
import { drawingMixin } from '../../../mixins/drawing.mixin';
import { projectService } from '../../../services/project.service';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import SingleProjectModal from '../../Modals/SingleProjectModal.vue';
// import ToastMessages from '../../ToastMessages.vue';
import CustomFloatingLabel from '../../CustomFloatingLabel';

export default {
    name: 'Map3DSidebar',
    components: {
        CustomFloatingLabel,
        SingleProjectModal,
    },
    mixins: [drawingMixin],
    props: {},
    data() {
        return {
            threeDVisDescription: '',
            threeDVisGeom: '',
            threeDVisName: '',
            showProjectSelectModal: false,
            selectedProject: null,
            selectedRadio: 0,
        };
    },
    created() {
        if (this.selectedProjectsArr.length > 1) {
            this.showProjectSelectModal = true;
        } else {
            this.selectedProject = this.selectedProjectsArr[0].ProjectID;
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateDrawPanePosition);
        this.$on('map/click', this.handleMouseClick);
        this.$on('map/dblClick', this.handleMouseDblClick);
        this.$on('ready', this.map3dReady);
        this.$emit('ready', this.mapObject);
    },
    render() {
        return null;
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        ...mapGetters('oneMap', ['map3dFeatureCount']),
        setSelectedProject(projID) {
            this.selectedProject = projID;
            this.showProjectSelectModal = false;
        },
        submit3DVisModel() {
            let payload = new FormData();
            let ProjectID = this.selectedProject;
            payload.append('ProjectId', ProjectID);
            payload.append('Description', this.threeDVisDescription);
            payload.append('Geometry', this.drawnPolygon);
            payload.append('Name', this.threeDVisName);
            if (document.getElementById('new3DModelUpload').files.length < 1) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage: 'No file attached',
                });
            } else {
                try {
                    payload.append(
                        'File',
                        document.getElementById('new3DModelUpload').files[0]
                    );

                    projectService
                        .Upload3DModel(payload)
                        .then((res) => {
                            this.setToastMessage({
                                showSuccess: true,
                                successToastMessage:
                                    '3D visualization model is submitted',
                            });
                            //call getProjectLayer api to refresh the map with just submitted 3D vis geometry
                            //this.getProjectLayerData(); //TODO: trigger SelectedProjects.vue? > getProjectLayerData()
                        })
                        .catch((err) => {
                            this.setToastMessage({
                                showFailure: true,
                                failureToastMessage: err.toString(), //need api to return a more user friendly error message
                            });
                        });
                } catch (error) {
                    this.setToastMessage({
                        showFailure: true,
                        failureToastMessage: 'Error attaching file',
                    });
                }
            }
        },
        clear3DVisModel() {
            this.threeDVisName = '';
            this.threeDVisDescription = '';
            this.$refs['new3DModelUpload'].reset();
            this.cancelDraw();
            this.clearDraw();
        },
        getMarkerFeature(lat, lng) {
            if (this.activeMapTool == 'map3d') {
                return {
                    // using letters and the count to create unique values between different object arrays
                    //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                    //    but not errors that will cause issues in the actual execution/functionality
                    // for this tool we use 3DC, 3DL, 3DP for 3D-Circle/Point, Measure-Line, and Polygon
                    //    this separates it from the drawtools C, L, P for Circle, Line Polygon
                    id: '3DC',
                    geometry: {
                        type: 'Point',
                        coordinates: [lat, lng],
                        coordSys: 'LatLong',
                    },
                    properties: {},
                    styling: {
                        //SymbologyColour: this.map3dShapeColor,
                        SymbologySize: 3,
                        //PolygonColor: this.map3dShapeColor,
                        PolygonTransparency: 0,
                    },
                };
            }
        },
        pointClickHandler(markerFeature) {
            this.clearMapTool();
            this.$store.commit('oneMap/addDrawMarker', markerFeature);
        },
        lineClickHandler(markerFeature) {
            if (this.drawLines.at(-1).geometry.coordinates.length == 0) {
                this.drawMarkers.push(markerFeature);
            } else if (this.drawLines.at(-1).geometry.coordinates.length == 1) {
                this.removeLastDrawMarker();
            }
            this.$store.commit(
                'oneMap/pushLineVertex',
                markerFeature.geometry.coordinates
            );
        },
        polygonClickHandler(markerFeature) {
            if (this.drawPolygons.at(-1).geometry.coordinates[0].length == 0) {
                this.$store.commit('oneMap/addDrawMarker', markerFeature);
            } else if (this.drawLines.at(-1).geometry.coordinates.length == 1) {
                this.removeLastDrawMarker();
            }
            this.$store.commit(
                'oneMap/pushLineVertex',
                markerFeature.geometry.coordinates
            );
            this.$store.commit(
                'oneMap/pushPolygonVertex',
                markerFeature.geometry.coordinates
            );
        },
        startDraw() {
            this.clearBufferShapes();
            this.draw3d();
        },
        cancelDraw() {
            this.clearMapTool();
        },
        clearDraw() {
            let drawMarkers = this.drawMarkers.filter(
                (feature) => !feature.id.startsWith('3DC')
            );
            let drawLines = this.drawLines.filter(
                (feature) => !feature.id.startsWith('3DL')
            );
            let drawPolygons = this.drawPolygons.filter(
                (feature) => !feature.id.startsWith('3DP')
            );
            this.$store.commit('oneMap/mutateDrawMarkers', drawMarkers);
            this.$store.commit('oneMap/mutateDrawLines', drawLines);
            this.$store.commit('oneMap/mutateDrawPolygons', drawPolygons);
            this.clearMapTool();
        },
        clearBufferShapes() {
            var i = this.drawMarkers.length;
            while (i--) {
                if (this.drawMarkers.at(i).id.startsWith('3D')) {
                    this.drawMarkers.splice(i, 1);
                }
            }
            i = this.drawLines.length;
            while (i--) {
                if (this.drawLines.at(i).id.startsWith('3D')) {
                    this.drawLines.splice(i, 1);
                }
            }
            i = this.drawPolygons.length;
            while (i--) {
                if (this.drawPolygons.at(i).id.startsWith('3D')) {
                    this.drawPolygons.splice(i, 1);
                }
            }
        },
        cleanupShapes() {
            var i = this.drawLines.length;
            while (i--) {
                if (this.drawLines.at(i).geometry.coordinates[0].length < 2) {
                    this.clearMapTool();
                }
            }
            i = this.drawPolygons.length;
            while (i--) {
                if (
                    this.drawPolygons.at(i).geometry.coordinates[0][0].length <
                    3
                ) {
                    this.drawPolygons.splice(i, 1);
                }
            }
        },
        draw3d() {
            this.cleanupShapes('polygon');
            if (this.activeMapTool == 'map3d') {
                this.cancelDraw();
            } else {
                this.setMapTool('map3d');
                this.drawLines.push({
                    id: '3DL' + this.drawLines.length,
                    geometry: {
                        coordinates: [],
                        type: 'LineString',
                        coordSys: 'LatLong',
                    },
                    properties: {
                        length: 0,
                    },
                    styling: {
                        //PolygonBorderColour: this.map3dShapeColor,
                    },
                });
                this.drawPolygons.push({
                    id: '3DP' + this.drawPolygons.length,
                    geometry: {
                        coordinates: [[]],
                        type: 'Polygon',
                        coordSys: 'LatLong',
                    },
                    properties: {
                        length: 0,
                    },
                    styling: {
                        //PolygonColor: this.map3dShapeColor,
                        //PolygonBorderColour: this.map3dShapeColor,
                        PolygonTransparency: 0.8,
                    },
                });
            }
        },
        map3dReady() {},
        threeDFileValidation() {
            var fileInput = document.getElementById('new3DModelUpload');
            var filePath = fileInput.value;
            // Allowed file type
            var allowedExtensions = /\.ctws$/i;
            var extensionCheck = allowedExtensions.exec(filePath);

            if (!extensionCheck) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage: 'Invalid file type',
                });
                fileInput.value = '';
                return false;
            }
        },
    },
    computed: {
        ...mapState('common', [
            'showSuccess',
            'showFailure',
            'successToastMessage',
            'failureToastMessage',
        ]),
        ...mapState({
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
        drawnPolygon() {
            let polygon3d = this.drawPolygons.find((obj) =>
                obj.id.startsWith('3D')
            );
            if (polygon3d == null) {
                return '';
            } else {
                return spatialFunctions.convertToWKT(polygon3d);
            }
        },
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
.outer-container {
    position: relative;
    float: left;
    height: calc(100vh - 126px);
    width: 100%;
    overflow: hidden;
    direction: ltr !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #d1d1d1;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    z-index: 10;
}
.content-container {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100vh - 224px);
}
.bottom-btn-container {
    align-self: flex-end;
    float: right;
    width: 100%;
    min-height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    text-align: center;
    justify-content: space-between;
    display: flex;
}
.map-btn {
    width: 100px;
    height: 48px;
    margin-right: 24px;
    margin-top: 24px;
}
.uploadThreeD-form {
    height: 50px;
}
.descText {
    color: #555555;
}
.buttonSelect {
    width: 25%;
    min-width: fit-content !important;
}
</style>
<style>
.my-custom-floating-label {
    display: block !important;
}
.input__container {
    width: auto !important;
}
.uploadThreeD-form > * {
    height: 100%;
}
.uploadThreeD-form .form-file-text {
    line-height: 2;
}
.uploadThreeD-form .custom-file-label {
    border: 1px solid #c6c6c6;
    color: #626262 !important;
    font-size: 16px;
    font-family: var(--ffr);
    font-weight: normal;
    padding-left: 18px;
    border-radius: 3px;
}
.uploadThreeD-form .custom-file-label::after {
    height: 100%;
    line-height: 2;
    color: #626262 !important;
    font-size: 16px;
    font-family: var(--ffr);
    font-weight: normal;
}
.bottom-btn-container .btn-secondary {
    background-color: #006395;
    border-color: #006395;
}
</style>

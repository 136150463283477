var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer-container"},[_c('div',{staticClass:"content-container p-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('CustomFloatingLabel',{attrs:{"config":{
                    label: 'Name',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.threeDVisName),expression:"threeDVisName"}],ref:"threeDVisName",staticClass:"normal_input",attrs:{"id":"threeDVisName","autocomplete":"off","type":"text","min":"0"},domProps:{"value":(_vm.threeDVisName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.threeDVisName=$event.target.value}}})]),_c('CustomFloatingLabel',{attrs:{"config":{
                    label: 'Description',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.threeDVisDescription),expression:"threeDVisDescription"}],ref:"threeDVisDescription",attrs:{"id":"threeDVisDescription","autocomplete":"off","type":"text"},domProps:{"value":(_vm.threeDVisDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.threeDVisDescription=$event.target.value}}})]),_c('b-form-file',{ref:"new3DModelUpload",staticClass:"mb-1 text-start uploadThreeD-form",attrs:{"accept":".ctws","id":"new3DModelUpload","name":"new3DModelUpload","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.threeDFileValidation()}}}),_c('div',{staticClass:"descText mt-2"},[_vm._v(" *Click Draw button, and then click on the map to draw a polygon, double click to finish drawing and submit an EVS model. ")]),_c('SingleProjectModal',{attrs:{"isShow":_vm.showProjectSelectModal},on:{"setSelectedProject":_vm.setSelectedProject}})],1)]),_c('div',{staticClass:"bottom-btn-container px-2"},[_c('div',{staticClass:"my-auto w-100"},[_c('b-button',{staticClass:"buttonSelect map-btn m-1 ",attrs:{"disabled":_vm.map3dFeatureCount() == true},on:{"click":_vm.startDraw}},[_vm._v(" Draw ")]),_c('b-button',{staticClass:"buttonSelect map-btn m-1",attrs:{"disabled":_vm.map3dFeatureCount() == false},on:{"click":_vm.clear3DVisModel}},[_vm._v(" Clear ")]),_c('b-button',{staticClass:"buttonSelect map-btn m-1",on:{"click":function($event){return _vm.submit3DVisModel()}}},[_vm._v(" Submit ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }